<template>
  <div>
    <!-- 기본 툴바 버튼 -->
    <v-tooltip bottom :disabled="!tooltip" v-if="!type || type == 'btn'">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="!type || type == 'btn'"
          v-show="showItem"
          v-on="on"
          v-bind="attrs"
          tile
          text
          :disabled="disabled"
          :class="{ 'cr-icon-btn': btnIcon, 'cr-tool-btn': !btnIcon }"
          @click="$emit('click')"
        >
          <v-icon v-if="btnIcon">{{ btnIcon }}</v-icon>
          <span v-else :style="textStyle">{{ title }}</span>
        </v-btn>
      </template>
      <span>{{ tooltip }}</span>
    </v-tooltip>

    <!-- 툴바 버튼 Outlined -->
    <v-tooltip bottom :disabled="!tooltip" v-else-if="type == 'btn-outline'">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          outlined
          color="primary"
          v-show="showItem"
          v-bind="attrs"
          v-on="on"
          :disabled="disabled"
          :class="{ 'cr-icon-btn': btnIcon, 'cr-tool-btn--outline': !btnIcon }"
          @click="$emit('click')"
        >
          <v-icon v-if="btnIcon">{{ btnIcon }}</v-icon>
          <span v-else :style="textStyle">{{ title }}</span>
        </v-btn>
      </template>
      <span>{{ tooltip }}</span>
    </v-tooltip>

    <!-- 메뉴형 툴바 버튼 -->
    <v-menu
      offset-y
      v-else-if="type == 'menu'"
      v-model="$data[vModel]"
      :close-on-content-click="!checkbox"
    >
      <!-- open / close btn -->
      <template v-slot:activator="{ on: onMenu, value }">
        <v-tooltip bottom :disabled="!tooltip || value">
          <template v-slot:activator="{ on: onTooltip }">
            <v-btn
              tile
              text
              :disabled="disabled"
              class="cr-tool-btn"
              :class="{ 'cr-icon-btn': btnIcon }"
              v-show="showItem"
              v-on="{ ...onMenu, ...onTooltip }"
            >
              <v-icon v-if="menuIcon">{{ menuIcon }}</v-icon>
              <span v-else>{{ title }}</span>
            </v-btn>
          </template>
          <span>{{ tooltip }}</span>
        </v-tooltip>
      </template>

      <!-- 메뉴 리스트 -->
      <v-list dense>
        <v-list-item-group
          color="primary"
          v-model="$data[`${vModel}Selectable`]"
          :multiple="checkbox"
        >
          <MenuListItem
            v-for="child in children"
            :subMenuAllChecked="subMenuAllChecked"
            :parentCheckbox="checkbox"
            :key="child.title"
            :title="child.title"
            :type="child.type"
            :showItem="child.showItem"
            :disabled="child.disabled"
            :vModel="child.vModel"
            :vValue="child.vValue"
            :children="child.children"
            :left="child.left"
            :selectable="child.selectable"
            :checkbox="child.checkbox"
            :appendIcon="child.appendIcon"
            @itemClick="title => itemClick(child, title)"
            @subMenuClick="subMenuClick"
          />
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<style scoped>
.v-list >>> .v-list-item__action {
  margin-right: 10px;
}
</style>

<style lang="scss" scoped>
.v-btn.cr-tool-btn::v-deep {
  height: 26px;
  min-width: auto;
  padding: 0px 8px;
  margin: 0px 4px;
  border: 1px solid;
  border-radius: 6px;
  border-color: transparent;

  &:hover {
    border-color: rgba(0, 0, 0, 0.12);
  }
  &:hover::before {
    opacity: 0.03 !important;
  }
}

.v-btn.cr-tool-btn--outline::v-deep {
  height: 26px;
  min-width: auto;
  padding: 0px 8px;
  margin: 0px 4px;
  border: 1px solid;
  border-radius: 6px;
}

.v-btn.cr-icon-btn {
  padding: 0px 6px;
  min-width: 32px;
  height: 26px;
  border-radius: 6px;
}
</style>

<script>
import MenuListItem from "./MenuListItem";

export default {
  methods: {
    // 메뉴 리스트 아이템 클릭
    itemClick(item, menuItemTitle) {
      const { vModel, children, checkbox } = this;
      if (checkbox) {
        // 클릭한 메뉴의 인덱스
        const menuItemIndex = children.findIndex(v => v.title == menuItemTitle);
        // 리스트 그룹 v-model
        const selectableModel = this.$data[`${vModel}Selectable`];
        // -1이면 클릭한거임 (*클릭 이벤트 후 v-model이 업데이트됨)
        const modelIndex = selectableModel.findIndex(v => v == menuItemIndex);
        const menuItem = children[menuItemIndex];

        // 현재 메뉴아이템이 체크박스를 사용하고 서브메뉴가 있을경우
        if (
          menuItem.type == "menu" &&
          menuItem.children &&
          menuItem.children.length > 0
        ) {
          // 서브 메뉴 체크
          if (modelIndex == -1) {
            this.subMenuAllChecked = { allChecked: true };
            // 서브 메뉴 체크 해제
          } else {
            this.subMenuAllChecked = {
              allChecked: false,
              num: 0
            };
          }
        }

        if (item.check) item.check(modelIndex == -1);
      } else if (item.click) item.click();
    },
    // 서브메뉴에서 체크박스 전부다 체크하면 상위 체크박스 체크
    subMenuClick(menuItemChecked, menuItemTitle, selectableModelLength) {
      const { vModel, children, checkbox } = this;
      if (!checkbox || !vModel) {
        this.$data[vModel] = null;
        return;
      }

      const menuItemIndex = children.findIndex(v => v.title == menuItemTitle);
      const selectableModel = this.$data[`${vModel}Selectable`];
      const modelIndex = selectableModel.findIndex(v => v == menuItemIndex);

      if (menuItemChecked && modelIndex == -1) {
        selectableModel.push(menuItemIndex);
        this.subMenuAllChecked = { allChecked: true };
      } else {
        selectableModel.splice(modelIndex, 1);
        this.subMenuAllChecked = {
          allChecked: false,
          num: selectableModelLength
        };
      }
    }
  },
  components: { MenuListItem },
  props: {
    title: {
      default: "",
      type: String,
      description: "툴바 버튼 title (필수 값)"
    },
    textStyle: {
      default: "",
      type: String,
      description: "버튼 스타일"
    },
    type: {
      default: "btn",
      type: String,
      description: "툴바 버튼 타입 btn / menu"
    },
    btnIcon: {
      default: undefined,
      type: String,
      description: "툴바 버튼 아이콘 ex) mdi-trash"
    },
    menuIcon: {
      default: undefined,
      type: String,
      description: "툴바 메뉴 버튼 아이콘 ex) mdi-trash"
    },
    tooltip: {
      default: undefined,
      type: String,
      description: "tooltip 텍스트"
    },
    vModel: {
      default: undefined,
      type: String,
      description: "툴바 메뉴 v-model (필수 값)"
    },
    vValue: {
      default: undefined,
      type: [Number, String],
      description:
        "툴바 메뉴 selectable 사용시 select값 / select변경시 같이 변경해줘야함 / children에 value값 필요"
    },
    showItem: {
      default: true,
      type: Boolean,
      description: "해당 아이템 보여주기 여부"
    },
    disabled: {
      default: false,
      type: Boolean,
      description: "툴바 버튼 비활성화 여부"
    },
    selectable: {
      default: false,
      type: Boolean,
      description: "툴바 메뉴 선택 사용 여부"
    },
    checkbox: {
      default: false,
      type: Boolean,
      description:
        "툴바 메뉴 체크박스 사용 여부 / 체크박스 사용시 체크박스 기본체크는 children에 checked값을 넣어줘야함"
    },
    children: {
      default: () => [],
      type: Array,
      description: "툴바 메뉴 아이템 리스트"
    }
  },
  mounted() {
    // 컴포넌트가 마운트되면 watch를 동적으로 걸어준다.
    Object.keys(this.$data).forEach(key => {
      if (key.endsWith("Selectable")) {
        this.$watch(
          `${key}`,
          function(next, prev) {
            const { selectable, checkbox, vValue, children } = this;

            // selectable이나 checkbox가 아닐경우 선택 초기화
            if (!selectable && !checkbox) {
              setTimeout(() => {
                this.$data[key] = null;
              }, 0);
            }

            // selectable일 경우 선택 해제 방지
            let index = prev;
            if (selectable && children && next == undefined) {
              if (vValue != undefined) {
                index = children.findIndex(({ value }) => vValue == value);
              }

              setTimeout(() => {
                this.$data[key] = checkbox ? [] : index;
              }, 0);
            }
          },
          { deep: true }
        );
      }
    });
  },
  watch: {
    children(newChildren) {
      const { vModel, vValue, selectable, checkbox } = this;

      if (vModel) {
        let index = null;
        if (vValue != undefined && selectable && newChildren) {
          index = newChildren.findIndex(({ value }) => vValue == value);
        }

        setTimeout(() => {
          this.$data[`${vModel}Selectable`] = checkbox ? [] : index;
          if (checkbox) {
            newChildren.forEach(({ checked }, idx) => {
              if (checked) this.$data[`${vModel}Selectable`].push(idx);
            });
          }
        }, 0);
      }
    }
  },
  data() {
    const { type, vModel, vValue, selectable, checkbox, children } = this;
    const data = {};
    if (type != "menu") return data;

    if (vModel) {
      data[vModel] = null;

      let index = null;
      if (vValue != undefined && selectable && children) {
        index = children.findIndex(({ value }) => vValue == value);
      }

      data[`${vModel}Selectable`] = checkbox ? [] : index;
      if (checkbox) {
        children.forEach(({ checked }, idx) => {
          if (checked) data[`${vModel}Selectable`].push(idx);
        });
      }
    }
    return { subMenuAllChecked: { allChecked: false }, ...data };
  }
};
</script>
