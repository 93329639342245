<template>
  <div>
    <v-list-item
      link
      v-if="!type || type == 'listItem'"
      v-show="showItem"
      :disabled="disabled"
      @click="() => $emit('itemClick', title)"
    >
      <template v-slot:default="{ active }">
        <v-list-item-action v-if="parentCheckbox">
          <v-checkbox :input-value="active" :ripple="false"></v-checkbox>
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>{{ title }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon v-if="appendIcon">
          <v-icon v-text="appendIcon"></v-icon>
        </v-list-item-icon>
      </template>
    </v-list-item>

    <v-menu
      offset-x
      open-on-hover
      v-else-if="type == 'menu'"
      :close-on-content-click="!parentCheckbox"
      :right="!left"
      :left="left"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-list-item
          v-show="showItem"
          v-bind="attrs"
          v-on="on"
          @click="() => $emit('itemClick', title)"
        >
          <template v-slot:default="{ active }">
            <v-list-item-action v-if="checkbox">
              <v-checkbox :input-value="active" :ripple="false"></v-checkbox>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{ title }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon v-if="left">mdi-chevron-left</v-icon>
              <v-icon v-else>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </template>
        </v-list-item>
      </template>

      <v-list dense>
        <v-list-item-group
          color="primary"
          v-model="$data[`${vModel}Selectable`]"
          :multiple="checkbox"
        >
          <SubMenuItem
            v-for="child in children"
            :key="child.title"
            :showItem="child.showItem"
            :disabled="child.disabled"
            :title="child.title"
            :appendIcon="child.appendIcon"
            :parentCheckbox="checkbox"
            @click="title => subMenuClick(child, title)"
          />
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<style scoped>
.v-list >>> .v-list-item__action {
  margin-right: 10px;
}
</style>

<script>
import SubMenuItem from "./SubMenuItem";

export default {
  components: { SubMenuItem },
  props: {
    subMenuAllChecked: {
      default: () => {},
      type: Object,
      description: "서브 메뉴 아이템 체크박스 전부 체크"
    },
    parentCheckbox: {
      default: false,
      type: Boolean,
      description: "메뉴 리스트 아이템 체크박스 사용 여부"
    },
    title: {
      default: "",
      type: String,
      description: "메뉴 리스트 아이템 타이틀 (필수 값)"
    },
    type: {
      default: "listItem",
      type: String,
      description: "메뉴 리스트 아이템 타입 listItem / menu"
    },
    showItem: {
      default: true,
      type: Boolean,
      description: "해당 메뉴 리스트 아이템 보여주기 여부"
    },
    disabled: {
      default: false,
      type: Boolean,
      description: "메뉴 리스트 아이템 비활성 여부"
    },
    appendIcon: {
      default: undefined,
      type: String,
      description: "메뉴 리스트 아이템 오른쪽 아이콘"
    },
    // type menu
    vModel: {
      default: undefined,
      type: String,
      description: "서브 메뉴 v-model (필수 값)"
    },
    vValue: {
      default: undefined,
      type: [Number, String],
      description:
        "서브 메뉴 selectable 사용시 select값 / select변경시 같이 변경해줘야함"
    },
    children: {
      default: () => [],
      type: Array,
      description: "서브 메뉴 아이템 리스트"
    },
    left: {
      default: false,
      type: Boolean,
      description: "서브 메뉴 열리는 방향"
    },
    selectable: {
      default: false,
      type: Boolean,
      description: "서브 메뉴 선택 사용 여부"
    },
    checkbox: {
      default: false,
      type: Boolean,
      description:
        "서브 메뉴 체크박스 사용 여부 / 체크박스 사용시 체크박스 기본체크는 children에 checked값을 넣어줘야함"
    }
  },
  watch: {
    subMenuAllChecked: function({ allChecked, num }) {
      const { vModel, children } = this;
      if (!allChecked && num == 0) {
        this.$data[`${vModel}Selectable`] = [];
      }

      if (allChecked) {
        this.$data[`${vModel}Selectable`] = [];
        children.forEach((child, idx) => {
          this.$data[`${vModel}Selectable`].push(idx);
        });
      }
    }
  },
  emits: ["click"],
  mounted() {
    // 컴포넌트가 마운트되면 watch를 동적으로 걸어준다.
    // selectabl 이 아닐때 선택초기화
    Object.keys(this.$data).forEach(key => {
      if (key.endsWith("Selectable")) {
        this.$watch(
          `${key}`,
          function(next, prev) {
            const { selectable, checkbox, vValue, children } = this;

            if (!selectable && !checkbox) {
              setTimeout(() => {
                this.$data[key] = null;
              }, 0);
            }

            let index = prev;
            if (selectable && children && next == undefined) {
              if (vValue != undefined) {
                index = children.findIndex(({ value }) => vValue == value);
              }

              setTimeout(() => {
                this.$data[key] = checkbox ? [] : index;
              }, 0);
            }
          },
          { deep: true }
        );
      }
    });
  },
  data() {
    const { type, vModel, vValue, selectable, checkbox, children } = this;
    const data = {};
    if (type != "menu") return data;

    if (vModel) {
      data[vModel] = null;

      let index = null;
      if (vValue != undefined && selectable && children) {
        index = children.findIndex(({ value }) => vValue == value);
      }

      data[`${vModel}Selectable`] = checkbox ? [] : index;
      if (checkbox) {
        children.forEach(({ checked }, idx) => {
          if (checked) data[`${vModel}Selectable`].push(idx);
        });
      }
    }

    return data;
  },
  methods: {
    subMenuClick(item, subItemTitle) {
      const { vModel, parentCheckbox, checkbox, children, title } = this;

      if (checkbox && vModel) {
        const subItemIndex = children.findIndex(v => v.title == subItemTitle);
        const selectableModel = this.$data[`${vModel}Selectable`];
        const subItemChecked =
          selectableModel.findIndex(v => v == subItemIndex) == -1;

        if (parentCheckbox && children.length > 0) {
          // 서브메뉴가 전부다 체크되면 부모 체크박스 체크 필요
          const selectableModelLength = subItemChecked
            ? selectableModel.length + 1
            : selectableModel.length - 1;
          const itemChecked = selectableModelLength == children.length;

          this.$emit("subMenuClick", itemChecked, title, selectableModelLength);
        }

        if (item.check) item.check(subItemChecked);
      } else if (item.click) {
        this.$emit("subMenuClick");
        item.click();
      }
    }
  }
};
</script>
