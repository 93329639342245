var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.type || _vm.type == 'listItem')?_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.showItem),expression:"showItem"}],attrs:{"link":"","disabled":_vm.disabled},on:{"click":function () { return _vm.$emit('itemClick', _vm.title); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [(_vm.parentCheckbox)?_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"ripple":false}})],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.title))])],1),(_vm.appendIcon)?_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.appendIcon)}})],1):_vm._e()]}}],null,false,407890239)}):(_vm.type == 'menu')?_c('v-menu',{attrs:{"offset-x":"","open-on-hover":"","close-on-content-click":!_vm.parentCheckbox,"right":!_vm.left,"left":_vm.left},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.showItem),expression:"showItem"}],on:{"click":function () { return _vm.$emit('itemClick', _vm.title); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [(_vm.checkbox)?_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"ripple":false}})],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.title))])],1),_c('v-list-item-icon',[(_vm.left)?_c('v-icon',[_vm._v("mdi-chevron-left")]):_c('v-icon',[_vm._v("mdi-chevron-right")])],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary","multiple":_vm.checkbox},model:{value:(_vm.$data[(_vm.vModel + "Selectable")]),callback:function ($$v) {_vm.$set(_vm.$data, (_vm.vModel + "Selectable"), $$v)},expression:"$data[`${vModel}Selectable`]"}},_vm._l((_vm.children),function(child){return _c('SubMenuItem',{key:child.title,attrs:{"showItem":child.showItem,"disabled":child.disabled,"title":child.title,"appendIcon":child.appendIcon,"parentCheckbox":_vm.checkbox},on:{"click":function (title) { return _vm.subMenuClick(child, title); }}})}),1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }