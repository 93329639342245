<template>
  <v-list-item
    link
    v-show="showItem"
    :disabled="disabled"
    @click="() => $emit('click', title)"
  >
    <template v-slot:default="{ active }">
      <v-list-item-action v-if="parentCheckbox">
        <v-checkbox :input-value="active" :ripple="false"></v-checkbox>
      </v-list-item-action>

      <v-list-item-content>
        <v-list-item-title>
          {{ title }}
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-icon v-if="appendIcon">
        <v-icon v-text="appendIcon"></v-icon>
      </v-list-item-icon>
    </template>
  </v-list-item>
</template>

<script>
export default {
  props: {
    title: {
      default: "",
      type: String,
      description: "서브 메뉴 타이틀 (필수 값)"
    },
    showItem: {
      default: true,
      type: Boolean,
      description: "서브 메뉴 아이템 보여주기 여부"
    },
    disabled: {
      default: false,
      type: Boolean,
      description: "서브 메뉴 아이템 비활성 여부"
    },
    parentCheckbox: {
      default: false,
      type: Boolean,
      description: "서브 메뉴 아이템 체크박스 사용여부"
    },
    appendIcon: {
      default: undefined,
      type: String,
      description: "서브 메뉴 아이템 오른쪽 아이콘"
    }
  }
};
</script>
