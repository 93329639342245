<template>
  <div style="padding: 5px 0px;">
    <!-- data-table header 안쓸때 checkbox사용 -->
    <v-checkbox
      hide-details
      color="gray"
      v-if="items.length >= 1"
      v-show="showCheckbox"
      :ripple="false"
      v-model="checked"
      :indeterminate="indeterminate"
    />

    <v-sheet :class="sheetStyle">
      <SlideItem
        v-for="item in items"
        :class="item.class"
        :ref="item.ref"
        :key="item.title || item.btnIcon || item.menuIcon"
        :title="item.title"
        :textStyle="item.textStyle"
        :type="item.type"
        :btnIcon="item.btnIcon"
        :menuIcon="item.menuIcon"
        :tooltip="item.tooltip"
        :vModel="item.vModel"
        :vValue="item.vValue"
        :showItem="item.showItem"
        :disabled="item.disabled"
        :selectable="item.selectable"
        :checkbox="item.checkbox"
        :children="item.children"
        @click="() => click(item)"
      />

      <v-spacer />

      <SlideItem
        v-for="item in rightItems"
        :class="item.class"
        :ref="item.ref"
        :key="item.title || item.btnIcon || item.menuIcon"
        :title="item.title"
        :type="item.type"
        :btnIcon="item.btnIcon"
        :menuIcon="item.menuIcon"
        :tooltip="item.tooltip"
        :vModel="item.vModel"
        :vValue="item.vValue"
        :showItem="item.showItem"
        :disabled="item.disabled"
        :selectable="item.selectable"
        :checkbox="item.checkbox"
        :children="item.children"
        @click="() => click(item)"
      />
      <div style="width: 11px" />
    </v-sheet>
  </div>
</template>

<style scoped>
.cr-sheet {
  padding-left: 38px;
}
.v-input.v-input--checkbox {
  position: absolute;
  top: 6px;
  left: 16px;
}
.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
.v-slide-group {
  width: 100%;
}
</style>

<script>
import SlideItem from "./SlideItem";

export default {
  components: {
    SlideItem
  },
  props: {
    checkbox: {
      default: false,
      type: Boolean,
      description: "체크박스 체크 여부"
    },
    showCheckbox: {
      default: true,
      type: Boolean,
      description: "체크박스 사용 여부"
    },
    indeterminate: {
      default: false,
      type: Boolean,
      description: "체크박스 미확정 여부"
    },
    items: {
      default: () => [],
      type: Array,
      description: "툴바 기본 아이템 리스트"
    },
    rightItems: {
      default: () => [],
      type: Array,
      description: "툴바 오른쪽 아이템 리스트"
    }
  },
  computed: {
    checked: {
      get() {
        return this.checkbox;
      },
      set(checked) {
        this.$emit("update:checkbox", checked);
      }
    },
    sheetStyle: function() {
      // d-none d-sm-flex
      return this.showCheckbox ? "d-flex mx-auto cr-sheet" : "d-flex mx-auto";
    }
  },
  methods: {
    // 기본 툴바 클릭 이벤트
    click(item) {
      if (item.click) item.click();
    }
  }
};
</script>
